import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { EnvironmentService, Environment } from '@galaxy/core';
import {
  AccountSelectorAccountInterface,
  AccountSelectorRedirectInterface,
  GetEntryURLWithCodeRequestInterface,
  GetEntryURLWithCodeResponseInterface,
  GetServiceProviderHostRequestInterface,
  GetServiceProviderHostResponseInterface,
  ListAccountSelectorAccountsResponseInterface,
  LoginRequestInterface,
  LoginResponseInterface,
  RequestPasswordResetRequestInterface,
  ResetPasswordRequestInterface,
  SsoRedirectRequestInterface,
} from './vendasta-login-center.api.interface';
import {
  GetEntryURLWithCodeRequest,
  GetEntryURLWithCodeResponse,
  GetServiceProviderHostRequest,
  GetServiceProviderHostResponse,
  ListAccountSelectorAccountsResponse,
  LoginRequest,
  LoginResponse,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  SsoRedirectRequest,
} from './vendasta-login-center';

interface ApiOptions {
  headers: HttpHeaders;
}

@Injectable({
  providedIn: 'root',
})
export class VendastaLoginCenterApiService {
  private _host: string;
  private _apiOptions: ApiOptions;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

  private get host(): string {
    if (!this._host) {
      switch (this.environmentService.getEnvironment()) {
        case Environment.PROD:
          this._host = 'https://login-prod.apigateway.co';
          break;
        default:
          this._host = 'https://login-demo.apigateway.co';
          break;
      }
    }
    return this._host;
  }

  private get apiOptions(): ApiOptions {
    if (!this._apiOptions) {
      this._apiOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
    }
    return this._apiOptions;
  }

  buildURL(route: string, params?: object): string {
    const url = new URL(`${this.host}${route}`);
    if (params) {
      const searchParams = new URLSearchParams({});
      for (const key in params) {
        if (!Object.prototype.hasOwnProperty.call(params, key)) {
          continue;
        }
        if (params[key] === null) {
          continue;
        }
        searchParams.append(key, params[key]);
      }
      url.search = searchParams.toString();
    }
    return url.toString();
  }

  login(r: LoginRequest | LoginRequestInterface): Observable<LoginResponse> {
    const request = (<LoginRequest>r).toApiJson ? <LoginRequest>r : new LoginRequest(r);
    const url = this.buildURL('/_ajax/v1/login');

    return this.http.post<LoginResponseInterface>(url, request.toApiJson(), this.apiOptions).pipe(
      map((resp: LoginResponseInterface) => LoginResponse.fromProto(resp)),
      share(),
    );
  }

  logout(): Observable<boolean> {
    const url = this.buildURL('/_ajax/v1/logout');

    return this.http
      .post(url, null, {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(
        map(() => true),
        share(),
      );
  }

  ssoRedirect(r: SsoRedirectRequest | SsoRedirectRequestInterface): void {
    const request = (<SsoRedirectRequest>r).toApiJson ? <SsoRedirectRequest>r : new SsoRedirectRequest(r);
    window.location.href = this.buildURL('/sso-redirect', request.toApiJson());
  }

  // accountSelectorRedirect performs redirection to use selected account to transfer to service
  accountSelectorRedirect(r: AccountSelectorRedirectInterface): void {
    const pathFragment = '/account-selector-redirect';
    const path = r.namespace ? `/${r.namespace}` + pathFragment : pathFragment;
    window.location.href = this.buildURL(path, r);
  }

  getEntryURLWithCode(r: GetEntryURLWithCodeRequest | GetEntryURLWithCodeRequestInterface): Observable<string> {
    const request = (<GetEntryURLWithCodeRequest>r).toApiJson
      ? <GetEntryURLWithCodeRequest>r
      : new GetEntryURLWithCodeRequest(r);
    const url = this.buildURL('/get-entry-url');

    return this.http.post<GetEntryURLWithCodeResponseInterface>(url, request.toApiJson(), this.apiOptions).pipe(
      map((resp: GetEntryURLWithCodeResponseInterface) => {
        const rp = GetEntryURLWithCodeResponse.fromProto(resp);
        return rp.entryUrl;
      }),
      share(),
    );
  }

  getServiceProviderHost(
    r: GetServiceProviderHostRequest | GetServiceProviderHostRequestInterface,
  ): Observable<string> {
    const request = (<GetServiceProviderHostRequest>r).toApiJson
      ? <GetServiceProviderHostRequest>r
      : new GetServiceProviderHostRequest(r);
    const url = this.buildURL('/get-service-provider-host');

    return this.http.post<GetServiceProviderHostResponseInterface>(url, request.toApiJson(), this.apiOptions).pipe(
      map((resp: GetServiceProviderHostResponseInterface) => {
        const rp = GetServiceProviderHostResponse.fromProto(resp);
        return rp.host;
      }),
      share(),
    );
  }

  requestPasswordReset(r: RequestPasswordResetRequest | RequestPasswordResetRequestInterface): Observable<boolean> {
    const request = (<RequestPasswordResetRequest>r).toApiJson
      ? <RequestPasswordResetRequest>r
      : new RequestPasswordResetRequest(r);
    const url = this.buildURL('/request-password-reset');

    return this.http.post(url, request.toApiJson(), this.apiOptions).pipe(
      map(() => true),
      share(),
    );
  }

  resetPassword(r: ResetPasswordRequest | ResetPasswordRequestInterface): Observable<boolean> {
    const request = (<ResetPasswordRequest>r).toApiJson ? <ResetPasswordRequest>r : new ResetPasswordRequest(r);
    const url = this.buildURL('/reset-password');

    return this.http.post(url, request.toApiJson(), this.apiOptions).pipe(
      map(() => true),
      share(),
    );
  }

  // listLogins returns the logins stored with this browser, optionally scoped to a namespace
  listLogins(namespace?: string): Observable<AccountSelectorAccountInterface[]> {
    const url = this.buildURL('/_ajax/v1/list-account-selector-accounts');
    const body = { namespace: namespace || '' };
    return this.http
      .post<ListAccountSelectorAccountsResponseInterface>(url, body, {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(
        map((resp: any): AccountSelectorAccountInterface[] | null => {
          const parsedResponse = ListAccountSelectorAccountsResponse.fromProto(resp);
          return parsedResponse ? parsedResponse.accounts || [] : [];
        }),
        share(),
      );
  }

  // removeAccountSelectorAccount clears any stored account with the user id specified
  removeAccountSelectorAccount(userId: string): Observable<boolean> {
    const url = this.buildURL('/_ajax/v1/remove-account-selector-account');
    const body = { user_id: userId };
    return this.http
      .post(url, body, {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(
        map(() => true),
        share(),
      );
  }
}

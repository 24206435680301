import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { VendastaLoginCenterService } from './vendasta-login-center.service';
import { VendastaLoginCenterApiService } from './vendasta-login-center.api.service';

@NgModule({
  imports: [CommonModule],
  providers: [VendastaLoginCenterService, VendastaLoginCenterApiService, provideHttpClient(withInterceptorsFromDi())],
})
export class VendastaLoginCenterModule {}

export interface SessionClaims {
  aud: string;
  exp: Date;
  iat: Date;
  iss: string;
  sub: string;
}

export function parseSession(session: string): SessionClaims {
  const pieces = session.split('.');
  if (pieces.length !== 3) {
    return null;
  }
  const body = pieces[1];
  const claims = JSON.parse(atob(body));
  return {
    aud: claims.aud,
    exp: new Date(claims.exp * 1000),
    iat: new Date(claims.iat * 1000),
    iss: claims.iss,
    sub: claims.sub,
  } as SessionClaims;
}

import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EnvironmentService } from '@galaxy/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { PartnerService, ServiceProviderService } from './core';
import { VendastaLoginCenterModule } from './vendasta-login-center/vendasta-login-center.module';

@NgModule({
  imports: [MatSnackBarModule, VendastaLoginCenterModule],
  providers: [SnackbarService, ServiceProviderService, PartnerService, EnvironmentService],
})
export class CommonModule {}

import {
  AccountSelectorAccountInterface,
  GetEntryURLWithCodeRequestInterface,
  GetEntryURLWithCodeResponseInterface,
  GetServiceProviderHostRequestInterface,
  GetServiceProviderHostResponseInterface,
  ListAccountSelectorAccountsResponseInterface,
  LoginRequestInterface,
  LoginResponseInterface,
  RequestPasswordResetRequestInterface,
  ResetPasswordRequestInterface,
  SsoRedirectRequestInterface,
} from './vendasta-login-center.api.interface';

export class LoginRequest implements LoginRequestInterface {
  email: string;
  password: string;
  namespace: string;
  type: string;
  rememberMe: boolean;
  recaptchaToken: string;
  recaptchaV2Token: string;
  serviceProviderId: string;

  static fromProto(proto): LoginRequest {
    if (!proto) {
      return null;
    }
    const m = new LoginRequest();
    for (const key in proto) {
      if (Object.prototype.hasOwnProperty.call(proto, key)) {
        m[key] = proto[key];
      }
    }
    return m;
  }

  constructor(kwargs?: LoginRequestInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (
      typeof this.email === 'undefined' &&
      typeof this.password === 'undefined' &&
      typeof this.namespace === 'undefined' &&
      typeof this.type === 'undefined' &&
      typeof this.rememberMe === 'undefined'
    ) {
      return null;
    }

    return {
      email: typeof this.email !== 'undefined' ? this.email : null,
      password: typeof this.password !== 'undefined' ? this.password : null,
      namespace: typeof this.namespace !== 'undefined' ? this.namespace : null,
      type: typeof this.type !== 'undefined' ? this.type : null,
      remember_me: typeof this.rememberMe !== 'undefined' ? this.rememberMe : null,
      'g-recaptcha-response': typeof this.recaptchaToken !== 'undefined' ? this.recaptchaToken : null,
      'g-recaptcha-v2-response': typeof this.recaptchaV2Token !== 'undefined' ? this.recaptchaV2Token : null,
      service_provider_id: typeof this.serviceProviderId !== 'undefined' ? this.serviceProviderId : null,
    };
  }
}

export class LoginResponse implements LoginResponseInterface {
  sessionId: string;

  static fromProto(proto): LoginResponse {
    if (!proto) {
      return null;
    }
    const m = new LoginResponse();
    m.sessionId = proto['session_id'];
    return m;
  }

  constructor(kwargs?: LoginResponseInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (typeof this.sessionId === 'undefined') {
      return null;
    }

    return {
      sessionId: typeof this.sessionId !== 'undefined' ? this.sessionId : null,
    };
  }
}

export class GetEntryURLWithCodeRequest implements GetEntryURLWithCodeRequestInterface {
  serviceProviderId: string;
  email: string;
  sessionId: string;
  nextUrl: string;
  context: string;
  namespace: string;

  static fromProto(proto): GetEntryURLWithCodeRequest {
    if (!proto) {
      return null;
    }
    const m = new GetEntryURLWithCodeRequest();
    for (const key in proto) {
      if (Object.prototype.hasOwnProperty.call(proto, key)) {
        m[key] = proto[key];
      }
    }
    return m;
  }

  constructor(kwargs?: GetEntryURLWithCodeRequestInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (
      typeof this.serviceProviderId === 'undefined' &&
      typeof this.email === 'undefined' &&
      typeof this.sessionId === 'undefined' &&
      typeof this.nextUrl === 'undefined' &&
      typeof this.namespace === 'undefined' &&
      typeof this.context === 'undefined'
    ) {
      return null;
    }

    return {
      service_provider_id: typeof this.serviceProviderId !== 'undefined' ? this.serviceProviderId : null,
      email: typeof this.email !== 'undefined' ? this.email : null,
      session_id: typeof this.sessionId !== 'undefined' ? this.sessionId : null,
      next_url: typeof this.nextUrl !== 'undefined' ? this.nextUrl : null,
      context: typeof this.context !== 'undefined' ? this.context : null,
      namespace: typeof this.context !== 'undefined' ? this.namespace : null,
    };
  }
}

export class GetEntryURLWithCodeResponse implements GetEntryURLWithCodeResponseInterface {
  entryUrl: string;

  static fromProto(proto): GetEntryURLWithCodeResponse {
    if (!proto) {
      return null;
    }
    const m = new GetEntryURLWithCodeResponse();
    m.entryUrl = proto['entry_url'];
    return m;
  }

  constructor(kwargs?: GetEntryURLWithCodeResponseInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (typeof this.entryUrl === 'undefined') {
      return null;
    }

    return {
      entry_url: typeof this.entryUrl !== 'undefined' ? this.entryUrl : null,
    };
  }
}

export class GetServiceProviderHostRequest implements GetServiceProviderHostRequestInterface {
  serviceProviderId: string;
  serviceContext: string;

  static fromProto(proto): GetServiceProviderHostRequest {
    if (!proto) {
      return null;
    }
    const m = new GetServiceProviderHostRequest();
    for (const key in proto) {
      if (Object.prototype.hasOwnProperty.call(proto, key)) {
        m[key] = proto[key];
      }
    }
    return m;
  }

  constructor(kwargs?: GetServiceProviderHostRequestInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (typeof this.serviceProviderId === 'undefined' && typeof this.serviceContext === 'undefined') {
      return null;
    }

    return {
      service_provider_id: typeof this.serviceProviderId !== 'undefined' ? this.serviceProviderId : null,
      context: typeof this.serviceContext !== 'undefined' ? this.serviceContext : null,
    };
  }
}

export class GetServiceProviderHostResponse implements GetServiceProviderHostResponseInterface {
  host: string;

  static fromProto(proto): GetServiceProviderHostResponse {
    if (!proto) {
      return null;
    }
    const m = new GetServiceProviderHostResponse();
    m.host = proto['host'];
    return m;
  }

  constructor(kwargs?: GetServiceProviderHostResponseInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (typeof this.host === 'undefined') {
      return null;
    }

    return {
      host: typeof this.host !== 'undefined' ? this.host : null,
    };
  }
}

export class RequestPasswordResetRequest implements RequestPasswordResetRequestInterface {
  email: string;
  personaType: string;
  serviceProviderId: string;
  namespace: string;

  static fromProto(proto): RequestPasswordResetRequest {
    if (!proto) {
      return null;
    }
    const m = new RequestPasswordResetRequest();
    for (const key in proto) {
      if (Object.prototype.hasOwnProperty.call(proto, key)) {
        m[key] = proto[key];
      }
    }
    return m;
  }

  constructor(kwargs?: RequestPasswordResetRequestInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (
      typeof this.email === 'undefined' &&
      typeof this.serviceProviderId === 'undefined' &&
      typeof this.namespace === 'undefined' &&
      typeof this.personaType === 'undefined'
    ) {
      return null;
    }

    return {
      email: typeof this.email !== 'undefined' ? this.email : null,
      persona_type: typeof this.personaType !== 'undefined' ? this.personaType : null,
      service_provider_id: typeof this.serviceProviderId !== 'undefined' ? this.serviceProviderId : null,
      namespace: typeof this.namespace !== 'undefined' ? this.namespace : null,
    };
  }
}

export class ResetPasswordRequest implements ResetPasswordRequestInterface {
  password: string;
  token: string;

  static fromProto(proto): ResetPasswordRequest {
    if (!proto) {
      return null;
    }
    const m = new ResetPasswordRequest();
    for (const key in proto) {
      if (proto.Object.prototype.hasOwnProperty.call(key)) {
        m[key] = proto[key];
      }
    }
    return m;
  }

  constructor(kwargs?: ResetPasswordRequestInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (typeof this.password === 'undefined' && typeof this.token === 'undefined') {
      return null;
    }

    return {
      password: typeof this.password !== 'undefined' ? this.password : null,
      token: typeof this.token !== 'undefined' ? this.token : null,
    };
  }
}

export class AccountSelectorAccount implements AccountSelectorAccountInterface {
  name: string;
  userId: string;
  email: string;
  loginRequired: boolean;

  static fromProto(proto): AccountSelectorAccount {
    if (!proto) {
      return null;
    }
    const m = new AccountSelectorAccount(proto);
    for (const key in proto) {
      if (Object.prototype.hasOwnProperty.call(proto, key)) {
        m[key] = proto[key];
      }
    }
    m.userId = proto['user_id'];
    m.loginRequired = proto['login_required'];
    return m;
  }

  constructor(kwargs?: ListAccountSelectorAccountsResponseInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }
}

export class ListAccountSelectorAccountsResponse implements ListAccountSelectorAccountsResponseInterface {
  accounts: AccountSelectorAccount[];

  static fromProto(proto): ListAccountSelectorAccountsResponse {
    if (!proto) {
      return null;
    }
    const m = new ListAccountSelectorAccountsResponse(proto);
    if (proto.accounts) {
      m.accounts = proto.accounts.map(AccountSelectorAccount.fromProto);
    }
    return m;
  }

  constructor(kwargs?: ListAccountSelectorAccountsResponseInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }
}

export class SsoRedirectRequest implements SsoRedirectRequestInterface {
  serviceProviderId: string;
  serviceContext: string;
  namespace: string;
  type: string;
  provider: string;
  nextUrl: string;
  errorUrl: string;

  static fromProto(proto): SsoRedirectRequest {
    if (!proto) {
      return null;
    }
    const m = new SsoRedirectRequest();
    for (const key in proto) {
      if (Object.prototype.hasOwnProperty.call(proto, key)) {
        m[key] = proto[key];
      }
    }
    return m;
  }

  constructor(kwargs?: SsoRedirectRequestInterface) {
    if (!kwargs) {
      return;
    }
    for (const key in kwargs) {
      if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
        this[key] = kwargs[key];
      }
    }
  }

  toApiJson(): object {
    if (
      typeof this.serviceProviderId === 'undefined' &&
      typeof this.serviceContext === 'undefined' &&
      typeof this.namespace === 'undefined' &&
      typeof this.type === 'undefined' &&
      typeof this.provider === 'undefined' &&
      typeof this.nextUrl === 'undefined' &&
      typeof this.errorUrl === 'undefined'
    ) {
      return null;
    }

    return {
      serviceProviderId: typeof this.serviceProviderId !== 'undefined' ? this.serviceProviderId : null,
      serviceContext: typeof this.serviceContext !== 'undefined' ? this.serviceContext : null,
      namespace: typeof this.namespace !== 'undefined' ? this.namespace : null,
      type: typeof this.type !== 'undefined' ? this.type : null,
      provider: typeof this.provider !== 'undefined' ? this.provider : null,
      nextUrl: typeof this.nextUrl !== 'undefined' ? this.nextUrl : null,
      errorUrl: typeof this.errorUrl !== 'undefined' ? this.errorUrl : null,
    };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServiceProviderService {
  private serviceProviderId$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public serviceProviderId$: Observable<string> = this.serviceProviderId$$
    .asObservable()
    .pipe(distinctUntilChanged(), publishReplay(1), refCount());

  public serviceIsPartnerCenter$: Observable<boolean> = this.serviceProviderId$.pipe(
    map((serviceProviderId: string) => serviceProviderId === 'AA'),
    publishReplay(1),
    refCount(),
  );

  public get serviceProviderId(): string {
    return this.serviceProviderId$$.getValue();
  }

  public get serviceIsPartnerCenter(): boolean {
    return this.serviceProviderId === 'AA';
  }

  setServiceProviderId(serviceProviderId: string): void {
    this.serviceProviderId$$.next(serviceProviderId);
  }
}

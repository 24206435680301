import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private partnerId$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public partnerId$: Observable<string> = this.partnerId$$
    .asObservable()
    .pipe(distinctUntilChanged(), publishReplay(1), refCount());

  public get partnerId(): string {
    return this.partnerId$$.getValue();
  }

  setPartnerId(partnerId: string): void {
    this.partnerId$$.next(partnerId);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { VendastaLoginCenterApiService } from './vendasta-login-center.api.service';
import { LoginResponse } from './vendasta-login-center';
import { PartnerService, ServiceProviderService } from '../core';
import { EnvironmentService } from '@galaxy/core';
import {
  AccountSelectorAccountInterface,
  AccountSelectorRedirectInterface,
} from './vendasta-login-center.api.interface';

// VendastaLoginCenterService is a wrapper around the API that provides some helpers knowing some of the context
//  ie partner and service provider in use
@Injectable({
  providedIn: 'root',
})
export class VendastaLoginCenterService {
  constructor(
    private api: VendastaLoginCenterApiService,
    private partnerService: PartnerService,
    private environmentService: EnvironmentService,
    private serviceProviderService: ServiceProviderService,
  ) {
    try {
      localStorage.removeItem('account-storage-lc');
    } catch (e) {
      //prevent eslint
    }
  }

  login(
    email: string,
    password: string,
    rememberMe: boolean,
    recaptchaV3Token: string,
    recaptchaV2Token,
    serviceProviderId?: string,
  ): Observable<string> {
    let type: string = null;
    const namespace: string = this.partnerService.partnerId;
    if (this.serviceProviderService.serviceIsPartnerCenter) {
      type = 'partner';
    }
    return this.api
      .login({
        email: email,
        password: password,
        namespace: namespace,
        type: type,
        rememberMe: rememberMe,
        recaptchaToken: recaptchaV3Token,
        recaptchaV2Token: recaptchaV2Token,
        serviceProviderId: serviceProviderId,
      })
      .pipe(
        take(1),
        map((resp: LoginResponse) => resp.sessionId),
      );
  }

  logout(): Observable<boolean> {
    return this.api.logout().pipe(take(1));
  }

  ssoRedirect(
    serviceProviderId: string,
    serviceContext?: string,
    provider?: string,
    nextUrl?: string,
    errorUrl?: string,
  ): void {
    let type: string = null;
    const namespace: string = this.partnerService.partnerId;
    if (this.serviceProviderService.serviceIsPartnerCenter) {
      type = 'partner';
    }

    return this.api.ssoRedirect({
      serviceProviderId,
      serviceContext,
      namespace,
      type,
      provider,
      nextUrl,
      errorUrl,
    });
  }

  getEntryUrlWithCode(
    serviceProviderId: string,
    email: string,
    sessionId: string,
    nextUrl: string,
    serviceContext?: string,
  ): Observable<string> {
    return this.api
      .getEntryURLWithCode({
        serviceProviderId: serviceProviderId,
        email: email,
        sessionId: sessionId,
        nextUrl: nextUrl,
        context: serviceContext,
        namespace: this.partnerService.partnerId,
      })
      .pipe(take(1));
  }

  getServiceProviderHost(serviceProviderId: string, serviceContext: string): Observable<string> {
    return this.api
      .getServiceProviderHost({
        serviceProviderId: serviceProviderId,
        serviceContext: serviceContext,
      })
      .pipe(take(1));
  }

  requestPasswordReset(email: string): Observable<boolean> {
    return this.api
      .requestPasswordReset({
        email: email,
        serviceProviderId: this.serviceProviderService.serviceProviderId,
        namespace: this.partnerService.partnerId,
      })
      .pipe(take(1));
  }

  resetPassword(newPassword: string, token: string): Observable<boolean> {
    return this.api
      .resetPassword({
        password: newPassword,
        token: token,
      })
      .pipe(take(1));
  }

  listLogins(namespace: string): Observable<AccountSelectorAccountInterface[]> {
    return this.api.listLogins(namespace).pipe(take(1));
  }

  removeAccountSelectorAccount(userId: string): Observable<boolean> {
    return this.api.removeAccountSelectorAccount(userId).pipe(take(1));
  }

  accountSelectorRedirect(
    userId: string,
    serviceProviderId: string,
    serviceContext: string,
    namespace?: string,
    nextUrl?: string,
    errorUrl?: string,
  ) {
    const req: AccountSelectorRedirectInterface = {
      userId: userId,
      serviceProviderId: serviceProviderId,
      context: serviceContext,
    };
    if (namespace) {
      req['namespace'] = namespace;
    }
    if (nextUrl) {
      req['nextUrl'] = nextUrl;
    }
    if (errorUrl) {
      req['errorUrl'] = errorUrl;
    }

    this.api.accountSelectorRedirect(req);
  }
}
